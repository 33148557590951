import React, { useEffect, useState } from "react"
import { useParams, useNavigate } from 'react-router-dom'
import ProgressSlaCard from "../../components/cards/ProgressSlaCard"
import DarkSelectInput from "../../components/forms/DarkSelectInput"
import CurrentDate from "../../components/resources/CurrentDate"
import BasicModal from "../../components/modals/BasicModal"
import BasicButton from "../../components/buttons/BasicButton"
import GraphCard from "../../components/cards/GraphCard"
import ReleaseAverageLeadTime from "./graphs/ReleaseAverageLeadTime"
import DeploymentToProduction from "./graphs/DeploymentToProduction"
import MeanTimeToRecovery from "./graphs/MeanTimeToRecovery"
import NumberOfEarlyDefects from "./graphs/NumberOfEarlyDefects"

const API = process.env.REACT_APP_DATA_API

const ToolchainDashboardInitiative = () => {
  let navigate = useNavigate()
  /* Company */
  //const currentCompany = localStorage.getItem('currentCompany')
  const currentCompany = localStorage.getItem('currentCompany')
  /* Timeframe options temp */
  const [selectedTimeframeLabel, setSelectedTimeframeLabel] = useState('Select Timeframe')
  const [selectedTimeframe, setSelectedTimeframe] = useState('historic')
  const timeframe = ['Today', 'Last Week', 'Last Two Weeks', 'Last Month']
  const timeframeNames = {
    'Today': 'lastDay',
    'Last Week': 'lastWeek',
    'Last Two Weeks': 'lastTwoWeeks',
    'Last Month': 'lastMonth'
  }

  const handleTimeframe = (value) => {
    setSelectedTimeframeLabel(value)
    setSelectedTimeframe(timeframeNames[value]) // for data filtering
  }
  /* Initiative Params */
  let { initiativeName } = useParams()
  const decodedInitiativeName = window.atob(initiativeName)
  /* Initiatives options temp */
  const [selectedInitiative, setSelectedInitiative] = useState(window.atob(initiativeName))
  const [initiatives, setInitiatives] = useState([])
  const [initiativeData, setInitiativeData] = useState([])
  const [initiativeConfigured, setInitiativeConfigured] = useState(true)
  const [initiativeId, setInitiativeId] = useState('')
  const [warningModalOpen, setWarningModalOpen] = useState(false)

  const handleInitiatives = (value) => {
    setSelectedInitiative(value)
  }

  /* Fetch initiatives list */
  const getInitiativeData = async () => {
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors',
      accept: 'application/json',
    }
    /* Current Initiative Data */
    const initiativeDataResponse = await fetch(`${API}/initiative-toolchain/${currentCompany}/${window.atob(initiativeName)}`, requestOptions)
    if(initiativeDataResponse.status === 200){
      const initiative_data = await initiativeDataResponse.json()
      if(Object.keys(initiative_data['toolchain_data']).length === 0){
        setInitiativeConfigured(false)
        setWarningModalOpen(true)
      }
      setInitiativeData(initiative_data['toolchain_data'])
      setInitiativeId(initiative_data['id'])
    }
  }

  const getCompanyInitiatives = async() => {
    resetFilters()
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors',
      accept: 'application/json',
    }
    /* Company Initiatives */
    const companyInitiativesResponse = await fetch(`${API}/company-toolchain/${currentCompany}`, requestOptions)
    const companyInitiatives = await companyInitiativesResponse.json()
    setInitiatives(companyInitiatives)

  }

  /* Order of the stages */
  const stageOrder = ["CODE", "BUILD", "TEST", "INTEGRATION", "DEPLOY", "OPERATE", "MONITOR"]

  /* Selected Tools */
  const defaultSelectedTools = {
    CODE: "All",
    BUILD: "All",
    TEST: "All",
    INTEGRATION: "All",
    DEPLOY: "All",
    OPERATE: "All",
    MONITOR: "All"
  }
  const [selectedTool, setSelectedTool] = useState(defaultSelectedTools)
  const resetFilters = () => {
    setSelectedTimeframe('historic')
    setSelectedTimeframeLabel('Select Timeframe')
    setSelectedTool(defaultSelectedTools)
  }

  const handleSlaTool = (key, value) => {
    setSelectedTool((prevSelectedTool) => ({
      ...prevSelectedTool,
      [key]: value
    }))
  }

  const handleAchieved = (stage) => {
    const selectedToolData = initiativeData[stage].tools.find(toolData => toolData.tool === selectedTool[stage]);
    if (selectedToolData) {
      const timeframe = selectedToolData.timeframes.find(tf => tf.timeframe === selectedTimeframe);
      return timeframe ? timeframe.achieved : 'ND';
    } else {
      return null;
    }
  }

  const returnToMainToolchain = () => {
    setWarningModalOpen(false)
    navigate(`/${btoa('dobcon-toolchain')}`)
  }

  const goToToolchainSetup = () => {
    setWarningModalOpen(false)
    navigate(`/${btoa('toolchain-setup')}`, { state: { initiative: initiativeId, mode:'edit' } })
  }

  const handleSuccess = (stage) => {
    const stageData = initiativeData[stage]?.tools || [];
    const toolData = stageData.find(tool => tool.tool === selectedTool[stage]) || {};
    const successValue = toolData?.timeframes ?
      toolData.timeframes.map(tf => tf.timeframe === selectedTimeframe ? tf.g : null) :
      'no data';

    return successValue;
  }

  /* Format stages */
  function capitalizeWords(sentence) {
    return sentence
      .toLowerCase()
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ')
  }

  /* Metric Cards */
  const [dtp, setDtp] = useState([])
  const [initiativeDtp, setInitiativeDtp] = useState({}) // Current initiative dat
  const [filteredDtp, setFilteredDtp] = useState({})

  const [edd, setEdd] = useState([])
  const [initiativeEdd, setInitiatvieEdd] = useState({})
  const [filteredEdd, setFilteredEdd] = useState({})

  const [releaseAvg, setReleaseAvg] = useState({})
  const [meanTimeToRecovery, setMeanTimeToRecovery] = useState({})
  
  const handleTimeframeChange = (selectedOption) => {
    setFilteredEdd(initiativeEdd.edd_by_timeframe.find(
      (item) => item.timeframe === selectedOption
    ))
    setFilteredDtp(initiativeDtp.dtp_per_timeframe.find(
      (item) => item.timeframe === selectedOption
    ))
  }

  const getCompanyMetricCards = async () => {
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors',
      accept: 'application/json',
    }

    // Deployments to Productio
    try {
      const dtp_response = await fetch(`${API}/metric-cards/dtp-fake/company/${currentCompany}`, requestOptions)
      if (dtp_response.status === 200) {
        const deploymensToProduction = await dtp_response.json()
        const dtpSummary = deploymensToProduction.find(
          (item) => item.initiative === decodedInitiativeName
        )
        setInitiativeDtp(dtpSummary)
        setDtp(deploymensToProduction)
        setFilteredDtp(dtpSummary.totals)
      }
    } catch (e) {
      console.error(e)
    }

    // Earky Defects
    try {
      const edd_response = await fetch(`${API}/metric-cards/edd-fake/company/${currentCompany}`, requestOptions)
      if (edd_response.status === 200) {
        const earlyDefects = await edd_response.json()
        const initiativeEddData = earlyDefects[decodedInitiativeName]
        setEdd(earlyDefects)
        setInitiatvieEdd(initiativeEddData)
        setFilteredEdd(initiativeEddData.edd_by_timeframe.find(
          (item) => item.timeframe === 'lastMonth'
        ))
      }
    } catch (e) {
      console.error(e)
    }

    // release avwerage preview
    try {
      const releaseAvgResponse = await fetch(`${API}/metric-cards/release-avg/company/${currentCompany}`, requestOptions)
      if (releaseAvgResponse.status === 200) {
        const releaseAvg = await releaseAvgResponse.json()
        setReleaseAvg(releaseAvg)
      }
    } catch (e) {
      console.error(e)
    }

    //Mean time to recovery
    try {
      const meanTimeToRecoveryResponse = await fetch(`${API}/metric-cards/mean-time-recovery/company/${currentCompany}`, requestOptions)
      if (meanTimeToRecoveryResponse.status === 200) {
        const meanTimeToRecovery = await meanTimeToRecoveryResponse.json()
        setMeanTimeToRecovery(meanTimeToRecovery)
      }
    } catch (e) {
      console.error(e)
    }
  }

  useEffect(() => {
    getCompanyInitiatives()
    getInitiativeData()
    getCompanyMetricCards()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initiativeName])

  return (
    <div className="containerToRight">
      <h1 className="mb2">
        <span className="lightGray">DOBCO<span className="bigN-h1">n</span></span> Toolchain Dashboard
      </h1>
      <div className="tar">
        <CurrentDate />
      </div>
      <span className="stepTag">Software Delivery Pipeline Stages</span>
      <hr className="grayLine" />

      {/* Head */}
      <div className="flexContainer spaceBetween mt2">
        <div className="col3 fw500 mb1">
          <span className="mr1"><span className="greenDot" /> Successful</span>
          <span><span className="redDot" /> Failed</span>
        </div>

        <div className="col8 flexContainer spaceBetween">
          {initiatives && (
          <div className="col6 flexContainer flexEnd mb1">
            <p className="fw500 mr1"><i className="fa-regular fa-folder" /> Initiative</p>
            <DarkSelectInput
              className="darkSelectInputMedium"
              options={initiatives.map(item => item.initiative)}
              value={selectedInitiative}
              onChange={handleInitiatives}
              type="link"
              link="dobcon-toolchain"
            />
          </div>
          )}
          <div className="col6 flexContainer flexEnd">
            <p className="fw500 mr1"><i className="fa-regular fa-clock" /> Timeframe</p>
            <DarkSelectInput
              className="darkSelectInputMedium"
              options={timeframe}
              value={selectedTimeframeLabel}
              onChange={handleTimeframe}
            />
          </div>
        </div>
      </div>

      {/* Alert if the initiatve is missconfigured */}
      
      <BasicModal isOpen={warningModalOpen}>
        <div className="tac">
          <>
            <p className="mb2 bigText">You have not configured the toolchain for this initiative yet.</p>
            <div className="mb1" />
            <BasicButton
              as="button"
              color="btnRed"
              size="btnFull"
              onClick={goToToolchainSetup}
            >
              Toolchain setup
            </BasicButton>
            <BasicButton
              as="button"
              color="btnBlack"
              size="btnFull"
              onClick={returnToMainToolchain}
            >
              Return
            </BasicButton>
          </>
        </div>
      </BasicModal>

      {/* Progress cards */}
      {initiativeConfigured && initiativeData && initiativeData.length !== 0 ?
        <div className="toolchainProgressCardsContainer">
          {Object.entries(initiativeData)
            .filter(([stage, value]) => stageOrder.includes(stage)) //ONly show existent cards
            .sort(([keyA], [keyB]) => stageOrder.indexOf(keyA) - stageOrder.indexOf(keyB))
            .map(([stage, value]) => (
              <ProgressSlaCard
                key={stage}
                all={false}
                stage={capitalizeWords(stage)}
                options={Object.values(value["tools"]).map((tool) => tool["tool"])}
                onChange={(selectedValue) => handleSlaTool(stage, selectedValue)}
                value={selectedTool[stage]}
                success={handleSuccess(stage)}
                fail={initiativeData[stage].tools.map(toolData =>
                  toolData.tool === selectedTool[stage]
                    ? toolData.timeframes?.find(tf => tf.timeframe === selectedTimeframe)?.r ?? 'no data'
                    : null
                )}
                agreed={initiativeData[stage].agreed_sla || null}
                achieved={handleAchieved(stage)}
              />
            )
            )}
        </div> :
        <section className="toolchainProgressCardsContainer">
          {stageOrder.map((stage) => (
            <ProgressSlaCard
              key={stage}
              all={false}
              stage={capitalizeWords(stage)}
              success="Loading..."
              fail="Loading..."
              agreed="0"
              achieved="0"
            />
          ))}
        </section>
      }

      {initiativeConfigured && (
      <div className="flexContainer spaceBetween mt1">
        <div className="col3 mb2">
          <GraphCard
            title="Release Average Lead Time (From First Commit)"
            graph={<><ReleaseAverageLeadTime dataset={releaseAvg} /></>}
          >
            <p className="mediumText">0 hh 0 mm</p>
          </GraphCard>
        </div>
        <div className="col3 mb2">
          <GraphCard
            title="Deployments to Production"
            graph={<><DeploymentToProduction dtp={dtp} initiative={decodedInitiativeName}/></>}
            onOptionChange={handleTimeframeChange}
          >
            <p className="mediumText">
              Successful: {filteredDtp?.g !== undefined ? filteredDtp.g : 0}
              <br />
              Failed: {filteredDtp?.r !== undefined ? filteredDtp.r : 0}
            </p>
          </GraphCard>
        </div>
        <div className="col3 mb2">
          <GraphCard
            title="Mean Time to Recovery (MTTR)"
            graph={<><MeanTimeToRecovery dataset={meanTimeToRecovery} /></>}
          >
            <p className="mediumText">0 hh 0 mm</p>
          </GraphCard>
        </div>
        <div className="col3 col6Tablet mb2">
          <GraphCard
            title="Total Production by Developer"
            graph="graph"
          >
            <p className="mediumText">1,260 Commits</p>
          </GraphCard>
        </div>
        <div className="col4 mb2">
          <GraphCard
            title="Number of Early Defects"
            graph={<><NumberOfEarlyDefects edd={edd} initiative={decodedInitiativeName}/></>} // pass edd data to graph
            onOptionChange={handleTimeframeChange}
          >
            <p className="mediumText">
              Detected: {filteredEdd?.detected !== undefined ? filteredEdd.detected : 0}
              <br />
              Solved: {filteredEdd?.solved !== undefined ? filteredEdd.solved : 0}
            </p>
          </GraphCard>
        </div>
        <div className="col4 mb2">
          <GraphCard
            title="Number of Software-Related Failures in Production"
            graph="graph"
          >
            <p className="mediumText">
              Detected: 4
              <br />
              Solved: 3
            </p>
          </GraphCard>
        </div>
        <div className="col4">
          <GraphCard
            title="Change Failure Rate"
            graph="graph"
          >
            <p className="mediumText">Graph</p>
          </GraphCard>
        </div>
      </div>
      )}
      
    </div>
  )
}

export default ToolchainDashboardInitiative