import React, { useEffect, useState } from "react"
import DarkSelectInput from "../../components/forms/DarkSelectInput"
import './ToolchainDashboard.css'
import ProgressSlaCard from "../../components/cards/ProgressSlaCard"
import GraphCard from "../../components/cards/GraphCard"
import DeploymentsToProduction from "./graphs/DeploymentToProduction"
import ReleaseAverageLeadTime from "./graphs/ReleaseAverageLeadTime"
import NumberOfEarlyDefects from "./graphs/NumberOfEarlyDefects"
import MeanTimeToRecovery from "./graphs/MeanTimeToRecovery"
import CurrentDate from "../../components/resources/CurrentDate"
import TotalProductionByDeveloper from "../../pages/toolchain/graphs/TotalProductionByDeveloper"
import generateMockTotalProductionByDeveloper from '../../test_metric_data/generateMockTotalProductionByDeveloper';
import ChangeFailureRate from '../../pages/toolchain/graphs/ChangeFailureRate';
import SoftwareFailures from '../../pages/toolchain/graphs/SoftwareFailures';

const API = process.env.REACT_APP_DATA_API

const ToolchainDashboard = () => {

  const sampleDatasetCFR = {
    changeFailureRate: [
      { timestamp: '2023-06-01', failures: 'Deployment Failure', count: 4, initiative: 'initiative_1' },
      { timestamp: '2023-06-02', failures: 'Configuration Error', count: 6, initiative: 'initiative_2' },
      { timestamp: '2023-06-03', failures: 'Script Error', count: 3, initiative: 'initiative_3' },
      { timestamp: '2023-06-04', failures: 'Network Issue', count: 2, initiative: 'initiative_1' },
      { timestamp: '2023-06-05', failures: 'Deployment Failure', count: 5, initiative: 'initiative_2' },
      { timestamp: '2023-06-06', failures: 'Configuration Error', count: 7, initiative: 'initiative_3' },
      { timestamp: '2023-06-07', failures: 'Script Error', count: 1, initiative: 'initiative_1' },
      { timestamp: '2023-06-08', failures: 'Network Issue', count: 4, initiative: 'initiative_2' },
      { timestamp: '2023-06-09', failures: 'Deployment Failure', count: 3, initiative: 'initiative_3' },
      { timestamp: '2023-06-10', failures: 'Configuration Error', count: 8, initiative: 'initiative_1' },
      { timestamp: '2023-06-11', failures: 'Script Error', count: 2, initiative: 'initiative_2' },
      { timestamp: '2023-06-12', failures: 'Network Issue', count: 3, initiative: 'initiative_3' },
      { timestamp: '2023-06-13', failures: 'Deployment Failure', count: 5, initiative: 'initiative_1' },
      { timestamp: '2023-06-14', failures: 'Configuration Error', count: 6, initiative: 'initiative_2' },
      { timestamp: '2023-06-15', failures: 'Script Error', count: 4, initiative: 'initiative_3' },
      { timestamp: '2023-06-16', failures: 'Network Issue', count: 7, initiative: 'initiative_1' },
      { timestamp: '2023-06-17', failures: 'Deployment Failure', count: 2, initiative: 'initiative_2' },
      { timestamp: '2023-06-18', failures: 'Configuration Error', count: 5, initiative: 'initiative_3' },
      { timestamp: '2023-06-19', failures: 'Script Error', count: 6, initiative: 'initiative_1' },
      { timestamp: '2023-06-20', failures: 'Network Issue', count: 4, initiative: 'initiative_2' },
      { timestamp: '2023-06-21', failures: 'Deployment Failure', count: 3, initiative: 'initiative_3' },
      { timestamp: '2023-06-22', failures: 'Configuration Error', count: 2, initiative: 'initiative_1' },
      { timestamp: '2023-06-23', failures: 'Script Error', count: 5, initiative: 'initiative_2' },
      { timestamp: '2023-06-24', failures: 'Network Issue', count: 8, initiative: 'initiative_3' },
      { timestamp: '2023-06-25', failures: 'Deployment Failure', count: 1, initiative: 'initiative_1' },
      { timestamp: '2023-06-26', failures: 'Configuration Error', count: 7, initiative: 'initiative_2' },
      { timestamp: '2023-06-27', failures: 'Script Error', count: 2, initiative: 'initiative_3' },
      { timestamp: '2023-06-28', failures: 'Network Issue', count: 3, initiative: 'initiative_1' },
      { timestamp: '2023-06-29', failures: 'Deployment Failure', count: 4, initiative: 'initiative_2' },
      { timestamp: '2023-06-30', failures: 'Configuration Error', count: 6, initiative: 'initiative_3' },
      { timestamp: '2023-07-01', failures: 'Script Error', count: 3, initiative: 'initiative_1' },
      { timestamp: '2023-07-02', failures: 'Network Issue', count: 5, initiative: 'initiative_2' },
      { timestamp: '2023-07-03', failures: 'Deployment Failure', count: 2, initiative: 'initiative_3' },
      { timestamp: '2023-07-04', failures: 'Configuration Error', count: 7, initiative: 'initiative_1' },
      { timestamp: '2023-07-05', failures: 'Script Error', count: 4, initiative: 'initiative_2' },
      { timestamp: '2023-07-06', failures: 'Network Issue', count: 3, initiative: 'initiative_3' },
      { timestamp: '2023-07-07', failures: 'Deployment Failure', count: 6, initiative: 'initiative_1' },
      { timestamp: '2023-07-08', failures: 'Configuration Error', count: 2, initiative: 'initiative_2' },
      { timestamp: '2023-07-09', failures: 'Script Error', count: 5, initiative: 'initiative_3' },
      { timestamp: '2023-07-10', failures: 'Network Issue', count: 8, initiative: 'initiative_1' },
      { timestamp: '2023-07-11', failures: 'Deployment Failure', count: 1, initiative: 'initiative_2' },
      { timestamp: '2023-07-12', failures: 'Configuration Error', count: 4, initiative: 'initiative_3' },
      { timestamp: '2023-07-13', failures: 'Script Error', count: 3, initiative: 'initiative_1' },
      { timestamp: '2023-07-14', failures: 'Network Issue', count: 6, initiative: 'initiative_2' },
      { timestamp: '2023-07-15', failures: 'Deployment Failure', count: 5, initiative: 'initiative_3' },
      { timestamp: '2023-07-16', failures: 'Configuration Error', count: 7, initiative: 'initiative_1' },
      { timestamp: '2023-07-17', failures: 'Script Error', count: 2, initiative: 'initiative_2' },
      { timestamp: '2023-07-18', failures: 'Network Issue', count: 4, initiative: 'initiative_3' },
      { timestamp: '2023-07-19', failures: 'Deployment Failure', count: 3, initiative: 'initiative_1' },
      { timestamp: '2023-07-20', failures: 'Configuration Error', count: 6, initiative: 'initiative_2' },
    ],
  };
  


  const sampleDatasetS = {
    softwareFailures: [
      { timestamp: '2024-07-15', failures: 'Database Connection', count: 3, initiative: 'initiative_1' },
      { timestamp: '2024-07-16', failures: 'API Timeout', count: 5, initiative: 'initiative_1' },
      { timestamp: '2024-07-17', failures: 'Memory Leak', count: 1, initiative: 'initiative_2' },
      { timestamp: '2024-07-18', failures: 'Service Crash', count: 2, initiative: 'initiative_1' },
      { timestamp: '2024-07-19', failures: 'Service Crash', count: 3, initiative: 'initiative_3' },
      { timestamp: '2024-07-20', failures: 'Database Connection', count: 4, initiative: 'initiative_2' },
      { timestamp: '2024-07-21', failures: 'API Timeout', count: 2, initiative: 'initiative_1' },
      { timestamp: '2024-07-22', failures: 'Service Crash', count: 6, initiative: 'initiative_3' },
      { timestamp: '2024-07-23', failures: 'Memory Leak', count: 1, initiative: 'initiative_2' },
      { timestamp: '2024-07-24', failures: 'Database Connection', count: 2, initiative: 'initiative_3' },
      { timestamp: '2024-07-25', failures: 'API Timeout', count: 5, initiative: 'initiative_1' },
      { timestamp: '2024-07-26', failures: 'Memory Leak', count: 1, initiative: 'initiative_2' },
      { timestamp: '2024-07-27', failures: 'Service Crash', count: 2, initiative: 'initiative_3' },
      { timestamp: '2024-07-28', failures: 'Service Crash', count: 3, initiative: 'initiative_1' },
      { timestamp: '2024-07-29', failures: 'Database Connection', count: 4, initiative: 'initiative_2' },
      { timestamp: '2024-07-30', failures: 'API Timeout', count: 2, initiative: 'initiative_1' },
      { timestamp: '2024-07-31', failures: 'Service Crash', count: 6, initiative: 'initiative_3' },
      { timestamp: '2024-08-01', failures: 'Memory Leak', count: 1, initiative: 'initiative_2' },
      { timestamp: '2024-08-02', failures: 'Database Connection', count: 2, initiative: 'initiative_3' },
      { timestamp: '2024-08-03', failures: 'API Timeout', count: 5, initiative: 'initiative_1' },
      { timestamp: '2024-08-04', failures: 'Memory Leak', count: 1, initiative: 'initiative_2' },
      { timestamp: '2024-08-05', failures: 'Service Crash', count: 2, initiative: 'initiative_3' },
      { timestamp: '2024-08-06', failures: 'Service Crash', count: 3, initiative: 'initiative_1' },
      { timestamp: '2024-08-07', failures: 'Database Connection', count: 4, initiative: 'initiative_2' },
      { timestamp: '2024-08-08', failures: 'API Timeout', count: 2, initiative: 'initiative_3' },
      { timestamp: '2024-08-09', failures: 'Service Crash', count: 6, initiative: 'initiative_1' },
      { timestamp: '2024-08-10', failures: 'Memory Leak', count: 1, initiative: 'initiative_2' },
      { timestamp: '2024-08-11', failures: 'Database Connection', count: 2, initiative: 'initiative_3' },
      { timestamp: '2024-08-12', failures: 'API Timeout', count: 5, initiative: 'initiative_1' },
      { timestamp: '2024-08-13', failures: 'Memory Leak', count: 1, initiative: 'initiative_2' },
      // Add more sample data as needed
    ],
  };
  

  /* Company */
  //const currentCompany = localStorage.getItem('currentCompany')
  const mockTotalProductionByDeveloper = generateMockTotalProductionByDeveloper();
  const currentCompany = localStorage.getItem('currentCompany')
  /* Timeframe options temp */
  const [selectedTimeframe, setSelectedTimeframe] = useState('Select Timeframe')
  const [selectedTimeframeFilter, setSelectedTimeframeFilter] = useState('historic')
  const [uploadeddtotalProductionByDeveloper, setUploadedtotalProductionByDeveloper] = useState(mockTotalProductionByDeveloper);
  const timeframe = ['Today', 'Last Week', 'Last Two Weeks', 'Last Month']
  const timeframeNames = {
    'Today': 'lastDay',
    'Last Week': 'lastWeek',
    'Last Two Weeks': 'lastTwoWeeks',
    'Last Month': 'lastMonth'
  }

  const handleTimeframe = (value) => {
    setSelectedTimeframe(value)
    setSelectedTimeframeFilter(timeframeNames[value])
  }

  /* Initiatives options */
  const [selectedInitiative, setSelectedInitiative] = useState('All')
  const [initiatives, setInitiatives] = useState([])
  const [initiativeNames, setInitiativeNames] = useState([])

  const handleInitiatives = (value) => {
    setSelectedInitiative(value)
  }
  /* Fetch initiatives list */
  const getCompanyInitiatives = async () => {
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors',
      accept: 'application/json',
    }
    try{
      const response = await fetch(`${API}/company-toolchain/${currentCompany}`, requestOptions)
      const data = await response.json()
      if(response.status === 200){
        setInitiatives(data)

        let namesInitiatives = []
        data.forEach(obj => {
          if(obj.initiative !== 'All')
          {
            namesInitiatives.push(obj.initiative)
          }
          
        })
        setInitiativeNames(namesInitiatives)
      }
    } catch(e){
      console.error(e)
    }
    
  }

  /* Metric Cards */
  const [dtp, setDtp] = useState({})
  const [filteredDtp, setFilteredDtp] = useState({}) // Apply timeframe filter
  const [edd, setEdd] = useState({})
  const [releaseAvg, setReleaseAvg] = useState({})
  const [meanTimeToRecovery, setMeanTimeToRecovery] = useState({}) 
  const [filteredEdd, setFilteredEdd] = useState({}) // Apply timeframe filter

  const handleTimeframeChange = (selectedOption, metric) => {
    if(metric === 'edd'){
      setFilteredEdd(edd.All.edd_by_timeframe.find(
        (item) => item.timeframe === selectedOption
      ))
    } else if(metric === 'dtp'){
      const dtpSummary = dtp.find(
        (item) => item.initiative === 'All'
      )
      setFilteredDtp(dtpSummary.dtp_per_timeframe.find(
        (item) => item.timeframe === selectedOption
      ))
    }
  }

  const getCompanyMetricCards = async () => {
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors',
      accept: 'application/json',
    }

    // Deployments to Productio
    try{
      const dtp_response = await fetch(`${API}/metric-cards/dtp-fake/company/${currentCompany}`, requestOptions)
      const dtp_data = await dtp_response.json()
      if(dtp_response.status === 200){
        setDtp(dtp_data)
        const dtpSummary = dtp_data.find(
          (item) => item.initiative === 'All'
        )
        setFilteredDtp(dtpSummary.totals)
      } else{
        console.error(dtp_data)
      }
    } catch (e){
      console.error(e)
    }

    // Earky Defects
    try{
      const edd_response = await fetch(`${API}/metric-cards/edd-fake/company/${currentCompany}`, requestOptions)
      const edd_data = await edd_response.json()
      if(edd_response.status === 200){
        setEdd(edd_data)
        setFilteredEdd(edd_data.All.edd_by_timeframe.find(
          (item) => item.timeframe === 'lastMonth'
        ))
      } else{
        console.error(edd_data)
      }
    } catch(e){
      console.error(e)
    }

    // release avwerage preview
    try {
      // Hardcoded JSON data
      const releaseAvg = {
        "contributions_release_avg_lead_time_per_day": [
          {
            "date": "2024-08-01",
            "code": 5,
            "test": 3,
            "build": 2,
            "integration": 1,
            "deploy": 4,
            "monitor": 2,
            "contribution": "Contrib_A",
            "initiative": "Initiative_1"
          },
          {
            "date": "2024-08-02",
            "code": 3,
            "test": 4,
            "build": 3,
            "integration": 2,
            "deploy": 5,
            "monitor": 3,
            "contribution": "Contrib_B",
            "initiative": "Initiative_2"
          },
          {
            "date": "2024-08-03",
            "code": 6,
            "test": 5,
            "build": 4,
            "integration": 3,
            "deploy": 3,
            "monitor": 2,
            "contribution": "Contrib_A",
            "initiative": "Initiative_1"
          },
          {
            "date": "2024-08-04",
            "code": 4,
            "test": 3,
            "build": 2,
            "integration": 4,
            "deploy": 4,
            "monitor": 5,
            "contribution": "Contrib_C",
            "initiative": "Initiative_3"
          },
          {
            "date": "2024-08-05",
            "code": 2,
            "test": 6,
            "build": 3,
            "integration": 5,
            "deploy": 3,
            "monitor": 2,
            "contribution": "Contrib_B",
            "initiative": "Initiative_2"
          }
        ]
      };
    
      // Directly setting the hardcoded JSON data
      setReleaseAvg(releaseAvg);
    } catch (e) {
      console.error(e);
    }
    

    //Mean time to recovery
    try {
      // Hardcoded JSON data
      const hardcodedMeanTimeToRecovery = {
          mttr: [
              {
                  outages: "outage_1",
                  timestamp: "2024-07-18",
                  hours: 11,
                  initiative: "Initiative_3"
              },
              {
                  outages: "outage_2",
                  timestamp: "2024-07-20",
                  hours: 22,
                  initiative: "Initiative_1"
              },
              {
                  outages: "outage_3",
                  timestamp: "2024-07-22",
                  hours: 8,
                  initiative: "Initiative_2"
              },
              {
                  outages: "outage_4",
                  timestamp: "2024-07-24",
                  hours: 14,
                  initiative: "Initiative_3"
              },
              {
                  outages: "outage_5",
                  timestamp: "2024-07-26",
                  hours: 10,
                  initiative: "Initiative_2"
              },
              {
                  outages: "outage_6",
                  timestamp: "2024-07-28",
                  hours: 20,
                  initiative: "Initiative_1"
              },
              {
                  outages: "outage_7",
                  timestamp: "2024-07-30",
                  hours: 3,
                  initiative: "Initiative_2"
              },
              {
                  outages: "outage_8",
                  timestamp: "2024-08-01",
                  hours: 17,
                  initiative: "Initiative_1"
              },
              {
                  outages: "outage_9",
                  timestamp: "2024-08-03",
                  hours: 19,
                  initiative: "Initiative_1"
              },
              {
                  outages: "outage_10",
                  timestamp: "2024-08-05",
                  hours: 23,
                  initiative: "Initiative_1"
              },
              {
                outages: "outage_11",
                timestamp: "2024-08-10",
                hours: 11,
                initiative: "Initiative_3"
              },
              {
                outages: "outage_12",
                timestamp: "2024-08-11",
                hours: 22,
                initiative: "Initiative_1"
              },
              {
                outages: "outage_13",
                timestamp: "2024-08-13",
                hours: 8,
                initiative: "Initiative_2"
              },
              {
                outages: "outage_14",
                timestamp: "2024-08-15",
                hours: 14,
                initiative: "Initiative_3"
              },
              {
                outages: "outage_15",
                timestamp: "2024-08-20",
                hours: 10,
                initiative: "Initiative_2"
              },
              {
                outages: "outage_16",
                timestamp: "2024-08-28",
                hours: 20,
                initiative: "Initiative_1"
              },
              {
                  outages: "outage_17",
                  timestamp: "2024-09-01",
                  hours: 3,
                  initiative: "Initiative_2"
              },
              {
                  outages: "outage_18",
                  timestamp: "2024-09-07",
                  hours: 17,
                  initiative: "Initiative_1"
              },
              {
                  outages: "outage_19",
                  timestamp: "2024-09-14",
                  hours: 19,
                  initiative: "Initiative_1"
              },
              {
                  outages: "outage_20",
                  timestamp: "2024-09-22",
                  hours: 23,
                  initiative: "Initiative_1"
              },
              // Add more hardcoded entries as needed
          ]
      };
  
      // Set the hardcoded data as the state
      setMeanTimeToRecovery(hardcodedMeanTimeToRecovery);
  } catch(e) {
      console.error(e);
  }
  
  }

  useEffect(() => {
    getCompanyInitiatives()
    getCompanyMetricCards()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const filteredInitiatives = selectedInitiative === "" ?
    initiatives : initiatives.filter(item => selectedInitiative.includes(item.initiative))

  /* Order of the stages */
  const stageOrder = ["CODE", "BUILD", "TEST", "INTEGRATION", "DEPLOY", "OPERATE", "MONITOR"]

  /* Format stages */
  function capitalizeWords(sentence) {
    return sentence
      .toLowerCase()
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ')
  }

  const testDetails = (numberOfFails) => {
    const failArray = Array.from({ length: numberOfFails }, () => {
      const randomInitiative = initiativeNames[Math.floor(Math.random() * initiativeNames.length)];
      return {
          tool: "source_tool",
          event_id: "f59822b9345n2nv428",
          initiative: randomInitiative,
          detail: "More information about the event (from tool)"
      };
    });
    return failArray;
  }

  const handleDetailsPostition = (stage) => {
    let position = 'right'
    const lastStages = ['MONITOR', 'OPERATE']
    if(lastStages.includes(stage)){
      position = 'left'
    }
    return position
  }

  return (
    <div className="containerToRight">
      <h1 className="mb2">
        <span className="lightGray">DOBCO<span className="bigN-h1">n</span></span> Toolchain Dashboard
      </h1>
      <div className="tar">
        <CurrentDate />
      </div>

      <span className="stepTag">Software Delivery Pipeline Stages</span>
      <hr className="grayLine" />

      {/* Head */}
      <div className="flexContainer spaceBetween mt2">
        <div className="col3 fw500 mb1">
          <span className="mr1"><span className="greenDot" /> Successful</span>
          <span><span className="redDot" /> Failed</span>
        </div>

        <div className="col8 flexContainer spaceBetween">
          <div className="col6 flexContainer flexEnd mb1">
            <p className="fw500 mr1"><i className="fa-regular fa-folder" /> Initiative</p>
            <DarkSelectInput
              className="darkSelectInputMedium"
              options={initiatives && initiatives.map(item => item.initiative)}
              value={selectedInitiative}
              onChange={handleInitiatives}
              type="link"
              link="dobcon-toolchain"
            />
          </div>
          <div className="col6 flexContainer flexEnd mb1">
            <p className="fw500 mr1"><i className="fa-regular fa-clock" /> Timeframe</p>
            <DarkSelectInput
              className="darkSelectInputMedium"
              options={timeframe}
              value={selectedTimeframe}
              onChange={handleTimeframe}
            />
          </div>
        </div>
      </div>
      
      {/* Progress cards */}
      {
        initiatives.length !== 0 ? filteredInitiatives.map((item, index) => (
          <div key={item.initiative} className='toolchainProgressCardsContainer'>
            {item?.toolchain_data && Object.entries(item.toolchain_data).sort(([keyA], [keyB]) => stageOrder.indexOf(keyA) - stageOrder.indexOf(keyB))
              .map(([stage, stage_data]) => (
                 stage_data && stage_data.map((tf) =>
                  tf.timeframe === selectedTimeframeFilter ?
                    <ProgressSlaCard
                      key={`${item.initiative}-${stage}`}
                      all={true}
                      stage={capitalizeWords(stage)}
                      success={tf.g}
                      fail={tf.r}
                      failDetails={testDetails(tf.r)}
                      successDetails={testDetails(tf.g)}
                      detailsPosition={handleDetailsPostition(stage)}
                      agreed={tf.agreed_sla}
                      achieved={tf.achieved}
                    /> : null
                )
              ))}
          </div>
        )) :
          <div className="toolchainProgressCardsContainer">
            {stageOrder.map((stage) => (
              <ProgressSlaCard
                key={stage}
                all={true}
                stage={capitalizeWords(stage)}
                success="Loading..."
                fail="Loading..."
                failDetails={[]}
                successDetails={[]}
                failDetailsDirection='right'
                agreed="0"
                achieved="0"
              />
            ))}
          </div>
      }

      {/* Graph cards */}
      <div className="flexContainer spaceBetween mt1">
        <div className="col3 mb2">
          <GraphCard
            title="Release Average Lead Time (From First Commit)"
            graph={<><ReleaseAverageLeadTime dataset={releaseAvg}/></>}
            onOptionChange={handleTimeframeChange} 
          >
            <p className="mediumText">0 hh 0 mm</p>
          </GraphCard>
        </div>
        <div className="col3 mb2">
          <GraphCard
            metric='dtp'
            title="Deployments to Production"
            graph={<><DeploymentsToProduction dtp={dtp} initiative='All'/></>}
            onOptionChange={handleTimeframeChange} 
          >
            <p className="mediumText">
              Successful: { filteredDtp && filteredDtp?.g !== undefined ? filteredDtp.g : 0}
              <br />
              Failed: {filteredDtp && filteredDtp?.r !== undefined ? filteredDtp.r : 0}
            </p>
          </GraphCard>
        </div>
        <div className="col3 mb2">
          <GraphCard
            title="Mean Time to Recovery (MTTR)"
            graph={<><MeanTimeToRecovery dataset={meanTimeToRecovery}/></>}
            onOptionChange={handleTimeframeChange} 
          >
            <p className="mediumText">0 hh 0 mm</p>
          </GraphCard>
        </div>
        <div className="col3 col6Tablet mb2">
          <GraphCard
            title="Total Production by Developer"
            graph={<><TotalProductionByDeveloper data={uploadeddtotalProductionByDeveloper} /></>}
            onOptionChange={handleTimeframeChange} 
          >
            <p className="mediumText">1,230 Commits</p>
          </GraphCard>
        </div>
        <div className="col4 mb2">
          <GraphCard
            metric='edd'
            title="Number of Early Defects"
            graph={<NumberOfEarlyDefects edd={edd} initiative={'All'}/>} // pass edd data to graph
            onOptionChange={handleTimeframeChange} 
          >
            <p className="mediumText">
              Detected: {filteredEdd && filteredEdd?.detected !== undefined ? filteredEdd.detected : 0}
              <br />
              Solved: {filteredEdd && filteredEdd?.solved !== undefined ? filteredEdd.solved : 0}
            </p>
          </GraphCard>
        </div>
        <div className="col4 mb2">
          <GraphCard
            title="Number of Software-Related Failures in Production"
            graph={<><SoftwareFailures dataset={sampleDatasetS} /></>}
            onOptionChange={handleTimeframeChange} 
          >
            <p className="mediumText">
              Detected: 4
              <br />
              Solved: 3
            </p>
          </GraphCard>
        </div>
        <div className="col4">
          <GraphCard
            title="Change Failure Rate"
            graph={<><ChangeFailureRate dataset={sampleDatasetCFR} /></>}
            onOptionChange={handleTimeframeChange} 
          >
            <p className="mediumText">
              Detected: 4
              <br />
              Solved: 3
            </p>
          </GraphCard>
        </div>
      </div>

    </div>
  )
}

export default ToolchainDashboard